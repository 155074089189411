body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;
  position: relative;
}

@media screen {

  html {
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .printTable {
    display: none;
  }

  .navlist li:nth-child(2),
  .navlist li:nth-child(3),
  .navlist li:nth-child(4) {
    color: red;
  }

  .output {
    line-break: auto;
    word-wrap: break-word;
    word-break: break-all;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .left10 {
    margin-left: 10px;
  }

  /*1126px*/
  .App {
    position: absolute;
    top: 0;
    background-image: url("./background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
  }

  .linebreak {
    display: block !important;
  }


  /*--- CUSTOM RADIOBUTTONS ---*/

  .container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    padding-left: 38px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    display: none;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 2px solid grey;
    border-radius: 50%;
  }

  input[type="radio"]:checked~.checkmark {
    border: 2px solid #3c49fa;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked~.checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3c49fa;
  }

  /*--- CUSTOM CHECKBOXES ---*/

  .error {
    border: 2px solid #f22 !important;
  }

  .inputError {
    border: 1px solid #f22 !important;
    background-color: #f221 !important;
  }

  .radioError {
    border: 2px solid #f22 !important;
    background-color: #f221 !important;
  }

  .checkBoxContainer {
    display: block;
    position: relative;
    padding-right: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkBoxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkBoxCheckmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    border-radius: 6px;
    border: 2px solid #bbb;
    background-color: white;
  }

  .checkBoxContainer:hover .checkBoxCheckmark {
    border-color: #3c49fa;
  }

  .checkBoxContainer input:checked~.checkBoxCheckmark {
    border-color: #3c49fa;
    background-color: white;
  }

  .checkBoxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkBoxContainer input:checked~.checkBoxCheckmark:after {
    display: block;
  }

  .checkBoxContainer .checkBoxCheckmark:after {
    left: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background: #3c49fa;
  }

  .clickable {
    cursor: pointer;
    user-select: none;
  }

  .mainLink {
    display: block;
  }

  .indexText {
    text-align: center;
  }

  .flexIndex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .indexImage {
    height: 200px;
    width: 200px;
    object-fit: contain;
    align-content: center;
    margin: 50px auto 20px auto;
  }

  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 20px;
    background: black;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #nav-icon4 {
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(2) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(3) {
    top: 16px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 8px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 14px;
    left: 8px;
  }

  .whiteBox .whiteBox {
    box-shadow: none;
    padding: 0;
  }

  .fieldset .fieldset {
    padding-top: 5px;
    margin-top: 5px;
  }

  .inner {
    margin-left: 30px;
  }

  .big {
    font-size: 16px;
    margin-top: 3px;
    font-weight: bold;
  }

  .small {
    font-weight: normal;
    font-size: 16px;
  }

  h5 {
    padding-top: 10px;
    padding-bottom: 13px;
    font-size: 16px;
  }

  .socialMediaItem {
    position: relative;
    height: auto;
    display: block;
    margin: 10px 20px;
    padding: 10px;
    border-radius: 4px;
  }

  .input:disabled {
    background-color: #bbb;
    border-color: #555;
    cursor: not-allowed;
  }

  .radioDisabled {
    background-color: #bbb;
    border-color: #555;
    cursor: not-allowed;
  }

  .nopointer {
    cursor: default !important;
  }

  /*Puhelin*/

  @media (max-width:640px) {

    .logo img {
      height: 30px;
      margin: 5px 10px 0 10px;
    }

    .whiteBox {
      width: 90vw;
      margin: 25px auto;
      padding: 5px 10px 20px 10px;
      box-shadow: 0px 0px 5px #333;
      z-index: 1;
      /* border: 1px solid #ccc; */
      border-radius: 4px;
    }

    .mainLink {
      font-size: 1.2em;
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid #999;
      padding: 5px;
      text-align: center;
    }

    .navbarRight {
      display: none;
      z-index: 10;
    }

    .displayThis {
      position: relative;
      display: block;
      background-color: white;
      margin-top: -1px;
      z-index: 20;
      box-shadow: 0px 2px 2px 0px grey;
    }

    .navbar {
      background-color: white;
      width: 100%;
      height: 40px;
      box-shadow: 0px 1px 3px #333;
      z-index: 22;
    }

    .navbar p {
      margin: 2px 0;
    }

    .navbarElement {
      display: block;
      width: 100vw;
      padding: 10px;
      z-index: 5;
      text-decoration: none;
      color: black;
    }

    .mobileNavButton {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: 10px 20px;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
    }

    .langSelector {
      margin-top: 0px;
      margin-bottom: 5px;
      padding: 10px 10px;
    }

    .langSelector:hover #langBlock {
      display: block;
    }

    .langBlock {
      display: none;
      background-color: white;
      margin-top: 10px;
    }

    .lang:hover {
      background-color: grey;
    }

    .ilmoSelector {
      /*text-align: center;
    width: 170px;*/
    }

    #langBlock.showLangs {
      display: block;
    }

    .ilmoLink {
      /*width: 170px;
    text-align: center;*/
      padding: 20px 10px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .ilmoBlock {
      display: none;
      background-color: white;
      /*position: absolute;*/
      top: 61px;
      left: 5px;
    }

    .ilmoLink:hover {
      background-color: grey;
    }

    .lang {
      padding: 10px 0px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .expander {
      cursor: pointer;
      user-select: none;
      width: 100%;
      padding: 10px 0;
      margin: 0px;
      font-size: 18px;
      border-top: 1px solid #8885;
      user-select: none;
    }

    .expander p {
      margin: 0 auto;
    }

    .rightExpander {
      float: right;
      margin-right: 10px;
    }

    .expandable {
      border-bottom: 1px solid #8885;
      margin-bottom: 1px;
      transition: 1s;
      margin-right: 10px;
    }

    .faqExpand {
      margin: 0 10px 20px 10px;
    }

    .faqExpand span {
      margin-bottom: 10px;
      display: block;
    }

    .faqItem h3 {
      margin-bottom: 10px !important;
    }

    .fieldset,
    .loginFieldset {
      width: 90%;
      margin: 10px auto;
    }

    .fieldset input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    select {
      margin: 5px 0;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 33px;
      width: 96%;
      padding-left: 10px;
      outline: none;
    }

    .fieldset textarea {
      margin: 5px 0px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 80px;
      width: 96%;
      padding-left: 10px;
      outline: none;
    }

    .fieldset label {
      margin-left: 1px;
    }

    .fieldset h4 {
      margin-top: 4px;
      margin-bottom: 8px;
    }


    .loginInput {
      width: 100%;
      margin-top: 15px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 96%;
      height: 33px;
      padding-left: 10px;
      outline: none;
    }

    .login {
      margin-top: 15px;
      padding: 14px;
      background-color: #3c49fa;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 5em;
    }

    .savebutton {
      margin-top: 15px;
      padding: 14px;
      background-color: #2daf21;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .inputGroup {
      margin-left: 0;
      margin-top: 5px;
    }

    .react-tel-input {
      display: inline-block !important;
      margin: 5px 0;
      width: 96%;
    }

    .react-tel-input input {
      width: 100% !important;
      margin: 0 !important;
    }

    /*--- events table ---*/
    .eventsTable {
      width: 96%;
      overflow-x: auto;
      text-align: center;
      display: block;
    }

    .eventsTable th,
    .eventsTable td,
    .eventsTable,
    .eventsTable Link {
      padding: 5px;
      padding-bottom: 8px;
      border-bottom: 1px solid #888;
      border-collapse: collapse;
    }

    .eventsTable td {
      cursor: pointer;
    }

    .attachment {
      position: relative;
      height: auto;
      display: flex;
      margin: 10px 0;
      padding: 10px;
      border-radius: 4px;
      background-color: #d8d8d8;
      align-items: center;
    }

    .attachmentDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .attachmentSlim img {
      height: 23px !important;
    }

    .attachmentSort {
      display: flex;
      margin-left: 5px;
      margin-right: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .attachmentSort span {
      margin-left: 2px;
    }

    .attachmentSortUp {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 0 7px 11px;
      border-bottom-color: blue;
      margin-bottom: 2px;
    }

    .attachmentSortDown {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 11px 7px 0;
      border-top-color: blue;
      margin-top: 2px;
    }

    .attachmentSortDisabled {
      border-top-color: #888 !important;
      border-bottom-color: #888 !important;
      cursor: default;
    }

    .attachment img {
      max-height: 60px;
      max-width: 100%;
      margin-right: 5px;
      margin-bottom: -5px;
      object-fit: contain;
    }

    .attachment a {
      text-decoration: none;
      color: black;
    }

    .alertbox {
      width: calc(90% - 10px);
      background-color: white;
      box-shadow: 0px 0px 20px #333;
      position: absolute;
      top: 100px;
      z-index: 99999;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #666;
      border-radius: 4px;
    }

    .alertbg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      background-color: #0004;
      z-index: 999;
    }

    .progContainer {
      width: 100%;
      margin: 10px auto 0px auto;
    }

    .progress {
      counter-reset: step;
      padding: 10px 0 0 0;
      width: 100%;
      align-content: center;
    }

    .progress li {
      list-style-type: none;
      /* width: 100px; */
      font-size: 12px;
      padding: 0 5px;
      position: relative;
      text-align: left;
      text-transform: uppercase;
      word-break: break-all;
      color: #7d7d7d;
      z-index: 10;
    }

    .progress li:before {
      width: 15px;
      height: 15px;
      content: counter(step);
      counter-increment: step;
      line-height: 15px;
      border: 2px solid #7d7d7d;
      display: inline-block;
      text-align: center;
      margin: 0 10px 5px 5px;
      border-radius: 50%;
      background-color: white;
      font-size: 11px;
      z-index: 20;
    }

    .progress li.active {
      color: #3c49fa;
    }

    .progress li.active:before {
      border-color: #3c49fa;
    }

    .progress li.active+li:after {
      background-color: #3c49fa;
    }

    .progress .current:before {
      color: white;
      background-color: #3c49fa;
      border-color: #3c49fa;
    }

    .progress .current {
      color: #3c49fa;
      font-weight: bold;
    }

    .progress .progError {
      color: red !important;
    }

    .progress li.progError:before {
      border-color: red !important;
      background-color: red !important;
      color: white !important;
    }
  }

  @media (min-width:641px) and (max-width: 1449px) {

    .logo img {
      height: 30px;
      margin: 5px 10px;
    }

    .whiteBox {
      width: 90%;
      margin: 30px calc(5vw - 20px) 30px calc(5vw - 30px);
      padding: 5px 20px 30px 20px;
      box-shadow: 0px 0px 5px #333;
    }

    .mainLink {
      font-size: 1.2em;
      max-width: 48%;
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid #999;
      padding: 5px;
      text-align: center;
    }


    .navbarRight {
      display: none;
      z-index: 10;
    }

    .displayThis {
      position: relative;
      display: block;
      background-color: white;
      margin-top: -1px;
      z-index: 10;
      box-shadow: 0px 2px 2px 0px grey;
    }

    .navbar {
      background-color: white;
      width: 100%;
      height: 40px;
      box-shadow: 0px 0px 3px grey;
      z-index: 11;
    }

    .navbar p {
      margin: 2px 0;
    }

    .navbarElement {
      display: block;
      width: 100vw;
      padding: 10px;
      z-index: 5;
      text-decoration: none;
      color: black;
    }

    .mobileNavButton {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      cursor: pointer;
      padding: 10px 20px;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
    }

    .langSelector {
      margin-top: 0px;
      margin-bottom: 5px;
      padding: 10px 10px;
    }

    .langSelector:hover #langBlock {
      display: block;
    }

    .langBlock {
      display: none;
      background-color: white;
      margin-top: 10px;
    }

    .lang:hover {
      background-color: grey;
    }

    .ilmoSelector {
      /*text-align: center;
    width: 170px;*/
    }

    #langBlock.showLangs {
      display: block;
    }

    .ilmoLink {
      /*width: 170px;
    text-align: center;*/
      padding: 20px 10px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .ilmoBlock {
      display: none;
      background-color: white;
      /*position: absolute;*/
      top: 61px;
      left: 5px;
    }

    .ilmoLink:hover {
      background-color: grey;
    }

    .lang {
      padding: 10px 0px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .expander {
      cursor: pointer;
      user-select: none;
      width: 100%;
      padding: 10px 0;
      margin: 0px;
      font-size: 18px;
      border-top: 1px solid #8885;
      user-select: none;
    }

    .expander p {
      width: 650px;
      margin: 0 auto;
    }

    .rightExpander {
      float: right;
    }

    .expandable {
      border-bottom: 1px solid #8885;
      margin-bottom: 1px;
      transition: 1s;
    }

    .faqExpand {
      margin: 0 10px 20px 10px;
    }

    .faqExpand span {
      margin-bottom: 10px;
      display: block;
    }

    .faqItem h3 {
      margin-bottom: 10px !important;
    }

    .fieldset,
    .loginFieldset {
      width: 541px;
      margin: 10px auto;
    }

    .listFieldset {
      width: 800px;
    }

    .fieldset input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    select {
      margin: 5px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 33px;
      width: 288px;
      padding-left: 10px;
      outline: none;
    }

    .loginInput {
      width: 100%;
      margin-top: 15px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 531px;
      height: 33px;
      padding-left: 10px;
      outline: none;
    }

    .login {
      margin-top: 15px;
      padding: 14px;
      background-color: #3c49fa;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .savebutton {
      margin-top: 15px;
      padding: 14px;
      background-color: #2daf21;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .inputGroup {
      margin-left: 230px;
    }

    .react-tel-input {
      display: inline-block !important;
      width: auto !important;
      margin: 5px;
      width: 200px !important;
    }

    .fieldset textarea {
      margin: 5px;
      margin-left: 235px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 80px;
      width: 288px;
      padding-left: 10px;
      outline: none;
    }

    .fieldset label {
      width: 230px;
      display: inline-block;
      margin-left: 1px;
    }

    .fieldset input[list] {
      width: 210px;
      display: inline-block;
      margin-left: 1px;
    }

    .fieldset h4 {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .linebreaker {
      display: block;
    }

    /*--- events table ---*/
    .eventsTable {
      width: 70%;
      margin-left: 15%;
      text-align: center
    }

    .eventsTable th,
    .eventsTable td,
    .eventsTable,
    .eventsTable Link {
      padding: 5px;
      padding-bottom: 8px;
      border-bottom: 1px solid #888;
      border-collapse: collapse;
    }

    .eventsTable td {
      cursor: pointer;
    }

    .attachment {
      position: relative;
      height: auto;
      display: flex;
      margin: 10px 0;
      padding: 10px;
      border-radius: 4px;
      background-color: #d8d8d8;
      align-items: center;
    }

    .attachmentDetails {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .attachmentSlim img {
      height: 23px !important;
    }

    .attachmentSort {
      display: flex;
      margin-left: 5px;
      margin-right: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .attachmentSort span {
      margin-left: 2px;
    }

    .attachmentSortUp {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 0 7px 11px;
      border-bottom-color: blue;
      margin-bottom: 2px;
    }

    .attachmentSortDown {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 11px 7px 0;
      border-top-color: blue;
      margin-top: 2px;
    }

    .attachmentSortDisabled {
      border-top-color: #888 !important;
      border-bottom-color: #888 !important;
      cursor: default;
    }

    .attachment img {
      max-height: 60px;
      max-width: 40%;
      margin-right: 10px;
      margin-bottom: -5px;
      object-fit: contain;
    }

    .attachment a {
      text-decoration: none;
      color: black;
    }

    .alertbox {
      width: 85%;
      max-width: 1000px;
      background-color: white;
      box-shadow: 0px 0px 20px #333;
      position: absolute;
      top: 100px;
      z-index: 99999;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #666;
      border-radius: 4px;
    }

    .alertbg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0004;
      z-index: 999;
    }

    .progContainer {
      width: 800px;
      margin: 10px auto 40px auto;
    }

    .progress {
      counter-reset: step;
      padding: 20px 0;
      width: 100%;
      align-content: center;
    }

    .progress li {
      list-style-type: none;
      width: calc(100%/7);
      float: left;
      font-size: 12px;
      padding: 0 5px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      word-break: break-all;
      color: #7d7d7d;
      z-index: 20;
    }

    .progress li:before {
      width: 15px;
      height: 15px;
      content: counter(step);
      counter-increment: step;
      line-height: 15px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 5px auto;
      border-radius: 50%;
      background-color: white;
      font-size: 11px;
      z-index: 20;
    }

    .progress li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 8px;
      left: -43%;
      z-index: -10;
    }

    .progress li:first-child:after {
      content: none;
    }

    .progress li.active {
      color: #3c49fa;
    }

    .progress li.active:before {
      border-color: #3c49fa;
    }

    .progress li.active+li:after {
      background-color: #3c49fa;
    }

    .progress .current:before {
      color: white;
      background-color: #3c49fa;
      border-color: #3c49fa;
    }

    .progress .current {
      color: #3c49fa;
      font-weight: bold;
    }

    .progress .progError {
      color: red !important;
    }

    .progress li.progError:before {
      border-color: red !important;
      background-color: red !important;
      color: white !important;
    }
  }

  @media (min-width: 1450px) and (max-width: 2200px) {

    /*--- events table ---*/
    .eventsTable {
      width: 100%;
      text-align: center
    }

    .eventsTable th,
    .eventsTable td,
    .eventsTable,
    .eventsTable Link {
      padding: 5px;
      padding-bottom: 8px;
      border-bottom: 1px solid #888;
      border-collapse: collapse;
    }

    .eventsTable td {
      cursor: pointer;
    }

  }

  @media (min-width: 2200px) {

    /*--- events table ---*/
    .eventsTable {
      width: 70%;
      margin-left: 15%;
      text-align: center
    }

    .eventsTable th,
    .eventsTable td,
    .eventsTable,
    .eventsTable Link {
      padding: 5px;
      padding-bottom: 8px;
      border-bottom: 1px solid #888;
      border-collapse: collapse;
    }

    .eventsTable td {
      cursor: pointer;
    }
  }

  @media (min-width:1450px) {

    .logo img {
      height: 45px;
      margin: 10px 20px;
    }

    .whiteBox {
      padding: 5px 30px 30px 30px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 50%;
      margin-left: 25%;
      box-shadow: 0px 0px 5px #333;
    }

    .mainLink {
      font-size: 1.2em;
      max-width: 48%;
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid #999;
      padding: 5px;
      text-align: center;
    }

    .inputGroup {
      margin-left: 230px;
    }

    .attachment {
      position: relative;
      height: auto;
      display: flex;
      margin: 10px 0;
      padding: 10px;
      border-radius: 4px;
      background-color: #d8d8d8;
      align-items: center;
    }

    .attachmentDetails {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .attachmentSlim img {
      height: 23px !important;
    }

    .attachmentSort {
      display: flex;
      margin-left: 5px;
      margin-right: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .attachmentSort span {
      margin-left: 2px;
    }

    .attachmentSortUp {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 0 7px 11px;
      border-bottom-color: blue;
      margin-bottom: 2px;
    }

    .attachmentSortDown {
      display: inline-block;
      height: 0;
      width: 0;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      border-width: 11px 7px 0;
      border-top-color: blue;
      margin-top: 2px;
    }

    .attachmentSortDisabled {
      border-top-color: #888 !important;
      border-bottom-color: #888 !important;
      cursor: default;
    }

    .attachment img {
      max-height: 60px;
      margin-right: 10px;
      margin-bottom: -5px;
      max-width: 40%;
      object-fit: contain;
    }

    .attachment a {
      text-decoration: none;
      color: black;
      margin-left: 10px;
    }

    .button {
      min-width: 100px;
    }

    /*--- NAVIGATION ---*/

    .navbar {
      background-color: white;
      display: block;
      height: 61px;
      width: 100%;
    }

    .navbarElement {
      display: inline-block;
      padding: 20px 10px;
      margin: 0 5px;
      text-decoration: none;
      color: black;
    }

    .navbarElement:hover {
      background-color: gray;
    }

    .navbarRight {
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
    }

    .notLoggedIn {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }

    .navbarLeft {
      position: absolute;
      left: 0;
      top: 0;
    }

    .langSelector {
      text-align: center;
      width: 70px;
    }

    .langSelector:hover #langBlock {
      display: block;
    }

    .langBlock {
      display: none;
      background-color: white;
      position: absolute;
      top: 61px;
      right: 5px;
      height: 122px;
    }

    .lang:hover {
      background-color: grey;
    }

    .ilmoSelector {
      text-align: center;
      width: 170px;
    }

    .ilmoSelector:hover #langBlock {
      display: block;
    }

    .ilmoLink {
      width: 170px;
      text-align: center;
      padding: 20px 10px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .ilmoBlock {
      display: none;
      background-color: white;
      position: absolute;
      top: 61px;
      left: 5px;
    }

    .ilmoLink:hover {
      background-color: grey;
    }

    .lang {
      width: 70px;
      text-align: center;
      padding: 20px 10px;
      display: block;
      text-decoration: none;
      color: black;
    }

    .mobileNavButton {
      display: none;
    }

    .expander {
      cursor: pointer;
      user-select: none;
      width: 100%;
      padding: 10px 0;
      margin: 0px;
      font-size: 18px;
      border-top: 1px solid #8885;
      user-select: none;
    }

    .expander p {
      width: 650px;
      margin: 0 auto;
    }

    .rightExpander {
      float: right;
    }

    .expandable {
      border-bottom: 1px solid #8885;
      margin-bottom: 1px;
      transition: 1s;
    }

    .faqExpand {
      margin: 0 10px 20px 10px;
    }

    .faqExpand span {
      margin-bottom: 10px;
      display: block;
    }

    .faqItem h3 {
      margin-bottom: 10px !important;
    }


    .fieldset,
    .loginFieldset {
      width: 541px;
      margin: 10px auto;
    }

    .listFieldset {
      width: 800px;
    }

    .fieldset input[type="text"],
    input[type="date"],
    input[type="number"],
    input[type="email"],
    select {
      margin: 5px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 33px;
      width: 286px;
      padding-left: 10px;
      outline: none;
    }

    .fileInput {
      display: block;
      margin-bottom: 5px;
    }

    .fileInputs {
      margin-top: 10px;
    }

    .loginInput {
      width: 100%;
      margin-top: 15px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      width: 531px;
      height: 33px;
      padding-left: 10px;
      outline: none;
    }

    .login {
      margin-top: 15px;
      padding: 14px;
      background-color: #3c49fa;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      min-width: 5em;
    }

    .savebutton {
      margin-top: 15px;
      padding: 14px;
      background-color: #2daf21;
      font-size: 16px;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
    }

    .react-tel-input {
      display: inline-block !important;
      width: auto !important;
      margin: 5px;
      width: 200px !important;
    }

    .fieldset textarea {
      margin: 5px;
      margin-left: 235px;
      border-radius: 3px;
      font-size: 14px;
      letter-spacing: .01rem;
      background: #FFFFFF;
      border: 1px solid #CACACA;
      border-radius: 5px;
      line-height: 25px;
      height: 80px;
      width: 288px;
      padding-left: 10px;
      outline: none;
    }

    .fieldset label {
      width: 230px;
      display: inline-block;
      margin-left: 1px;
    }

    .fieldset input[list] {
      width: 210px;
      display: inline-block;
      margin-left: 1px;
    }

    .fieldset h4 {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .linebreaker {
      display: block;
    }

    .progContainer {
      width: 800px;
      margin: 10px auto 40px auto;
    }

    .progress {
      counter-reset: step;
      padding: 20px 0;
      width: 100%;
      align-content: center;
    }

    .progress li {
      list-style-type: none;
      width: calc(100%/7);
      float: left;
      font-size: 12px;
      padding: 0 5px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      word-break: break-all;
      color: #7d7d7d;
      z-index: 20;
    }

    .progress li:before {
      width: 15px;
      height: 15px;
      content: counter(step);
      counter-increment: step;
      line-height: 15px;
      border: 2px solid #7d7d7d;
      display: block;
      text-align: center;
      margin: 0 auto 5px auto;
      border-radius: 50%;
      background-color: white;
      font-size: 11px;
      z-index: 20;
    }

    .progress li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 8px;
      left: -43%;
      z-index: -10;
    }

    .progress li:first-child:after {
      content: none;
    }

    .progress li.active {
      color: #3c49fa;
    }

    .progress li.active:before {
      border-color: #3c49fa;
    }

    .progress li.active+li:after {
      background-color: #3c49fa;
    }

    .progress .current:before {
      color: white;
      background-color: #3c49fa;
      border-color: #3c49fa;
    }

    .progress .current {
      color: #3c49fa;
      font-weight: bold;
    }

    .progress .progError {
      color: red !important;
    }

    .progress li.progError:before {
      border-color: red !important;
      background-color: red !important;
      color: white !important;
    }


    .buttonGroupRight {
      width: 800px;
      text-align: right;
      margin: 0 auto;
    }

    .buttonGroupRight button {
      margin-right: 20px;
    }

    .subForm {
      width: 700px;
      margin: 10px auto;
    }

    .alertbox {
      width: 1000px;
      background-color: white;
      box-shadow: 0px 0px 20px #333;
      position: absolute;
      top: 100px;
      z-index: 99999;
      left: 50%;
      margin-left: -490px;
      padding: 20px;
      border: 1px solid #666;
      border-radius: 4px;
    }

    .alertbg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: #0006;
      z-index: 999;
    }
  }

  .whiteBox {
    background-color: white;
    border-radius: 4px;
  }

  .errors {
    font-size: 18px;
    color: #f11;
    font-weight: bold;
    text-align: right;
    margin-top: 5px;
  }


  .registerFieldset,
  .loginButtonFieldset {
    text-align: right;
  }

  .register {
    margin-top: 10px;
    padding: 14px;
    background-color: #3c49fa;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
  }

  button,
  input[type="submit"] {
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
    box-shadow: 0px 0px 3px grey;
  }

  .login:disabled,
  .savebutton:disabled,
  .register:disabled {
    cursor: not-allowed;
    background-color: #888;
  }

  .goToRegister {
    padding: 14px;
    margin-top: 20px;
    padding: 14px;
    background-color: #3c49fa;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 4px;
    text-decoration: none;
  }

  .removeButton {
    padding: 14px;
    margin-top: 10px;
    background-color: #f10;
    border: unset;
    color: white;
    border-radius: 4px;
  }

  .remove_button {
    padding: 6px 10px;
    margin-top: 10px;
    background-color: #f10;
    border: unset;
    color: white;
    border-radius: 4px;
  }

  .editButton {
    padding: 14px;
    margin-top: 10px;
    background-color: #3c49fa;
    border: unset;
    color: white;
    border-radius: 4px;
  }

  .deleteButton {
    position: absolute;
    right: 2px;
    background-color: #f10;
    padding: 4px;
    border: unset;
    color: white;
    border-radius: 4px;
    bottom: 7px;
  }

  .relativeDeleteButton {
    background-color: #f10;
    padding: 9px;
    border: unset;
    color: white;
    border-radius: 4px;
  }

  .addButton {
    background-color: #3c49fa;
    padding: 9px;
    border: unset;
    color: white;
    border-radius: 4px;
  }

  .addButton:disabled {
    background-color: #7b84ff;
    cursor: default;
  }

  .configImg {
    max-height: 100px;
    padding: 20px 0px;
  }
}


@media print {
  label {
    margin-right: 5px;
  }
}

.blue {
  color: blue;
}

.priceTable {
  overflow-x: scroll;
  padding-bottom: 10px;
}

.printTable.printTable {
  font-size: 0.83em;
}

.narrow {
  width: 150px !important;
  margin: 5px;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: .01rem;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 33px;
  width: 288px;
  padding-left: 10px;
  outline: none;
}

.editDivider {
  margin: 50px 0px;
}

.faqAnswer {
  white-space: pre-wrap;
}

.edits {
  margin: 3px 0 3px 0;
}

.logField {
  padding: 3px 0 15px 0;
  border-bottom: 1px solid #888;
}

.logObject {
  padding-left: 10px;
}

.capsred {
  color: red;
  text-transform: uppercase;
  font-weight: bold;
}
